import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Switch,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import {
  ORDER_DIRECTION,
  RESOURCE_STATUS,
  ROLES,
  TOAST_STATUS,
  PAGINATION_VALUES,
  DEFAULT_PAGINATION_VALUE,
} from 'utils/constants';
import {
  fetchAlarmsCountThunk,
  fetchMonitorsThunk,
  getAlarmsCount,
  getMonitors,
  updateMonitorsCompanyThunk,
  updateMonitorsZoneThunk,
} from 'stores/monitors';
import i18n from 'lib/i18n';
import { tType } from 'types';
import capitalize from 'lodash.capitalize';
import { getCurrentCompanyId } from 'lib/auth';
import { getCurrentUser } from 'stores/currentUser';
import { getCompanyList, getCurrentCompany } from 'stores/company';
import AssignMonitorsToCompanyModal from 'components/AssignMonitorsToCompanyModal';
import Filters from 'components/Filters';
import ColumnHeader from 'components/ColumnHeader';
import { fetchZonesThunk, getZonesByCompany } from 'stores/zones';
import ZoneModal from 'components/ZoneModal';
import { handleToastMessage } from 'utils/toast';
import {
  subscribeToMonitorThunk,
  unsubscribeToMonitorThunk,
} from 'stores/monitor';
import useMonitorListMap from 'hooks/useMonitorListMap';
import useMapMonitors from 'hooks/useMapMonitors';
import { MonitorsEmptyState } from 'components/EmptyState/emptyStates';
import MonitorsDataDownloadAction from 'components/MonitorsDataDownloadAction';
import Row from './Row/index';
import { COLUMN_HEADERS } from './constants';
import SearchBar from '../SearchBar/SearchBar';
import SearchFilters from './SearchFilters';

const { withTranslation } = i18n;

const { IDLE, LOADING, READY } = RESOURCE_STATUS;

const MonitorsTable = ({ t }) => {
  const {
    isOpen: isMoveToCompanyOpen,
    onOpen: onOpenMoveToCompany,
    onClose: onCloseMoveToCompany,
  } = useDisclosure();
  const {
    isOpen: isMoveToZoneOpen,
    onOpen: onOpenMoveToZone,
    onClose: onCloseMoveToZone,
  } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  const router = useRouter();
  const { items: zones } = useSelector(getZonesByCompany);
  const monitors = useSelector(getMonitors);
  const companies = useSelector(getCompanyList);
  const { zoneId, zoneName, alarm } = router.query;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isInMapMode, setIsInMapMode] = useState(false);
  const [alarmFilter, setAlarmFilter] = useState(alarm);
  const [firstLoading, setFirstLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('description');
  const [orderDirection, setOrderDirection] = useState(
    ORDER_DIRECTION.ASCENDENT,
  );
  const [
    areAllCompaniesMonitorsHidden,
    setAreAllCompaniesMonitorsHidden,
  ] = useState(false);
  const [areInactiveMonitorsVisible, setAreInactiveMonitorsVisible] = useState(
    false,
  );
  const currentCompanyId = getCurrentCompanyId();
  const currentUser = useSelector(getCurrentUser);
  const { role } = currentUser;

  const {
    value: currentCompany,
    resourceStatus: currentCompanyResourceStatus,
  } = useSelector(getCurrentCompany);
  const {
    data: alarmsCount,
    resourceStatus: alarmsCountResourceStatus,
  } = useSelector(getAlarmsCount);
  const [monitorsAssigned, setMonitorsAssigned] = useState([]);
  const [selectedParentZone, setSelectedParentZone] = useState(null);
  const [perPage, setPerPage] = useState(DEFAULT_PAGINATION_VALUE);
  const isXactAdmin = role === ROLES.xactAdmin;
  const isCommonUser = role === ROLES.commonUser;
  const isCurrentCompanyDefault = currentCompany?.default;
  const isCompanyAdminPartner =
    role === ROLES.companyAdmin && currentUser.isCompanyAdminPartner;
  const PER_PAGE_VALUES = PAGINATION_VALUES;
  const mapMonitors = useMapMonitors({
    isInMapMode,
    search,
    currentCompanyId,
    zoneId,
    includeInactive: areInactiveMonitorsVisible,
    ...(alarmFilter !== '' && { alarmFilter }),
  });
  const monitorsWithCoordinates = useMemo(() => {
    return mapMonitors?.items?.filter(
      monitor => monitor.longitude && monitor.latitude,
    );
  }, [mapMonitors.items]);
  const mapContainer = useMonitorListMap(monitorsWithCoordinates, isInMapMode);
  const isCurrentCompanyIdAvailable =
    areAllCompaniesMonitorsHidden || !isCurrentCompanyDefault;
  const fetchMonitors = useCallback(
    async pageNumber => {
      await dispatch(
        fetchMonitorsThunk({
          orderBy,
          orderDirection,
          page: pageNumber,
          search,
          perPage,
          includeInactive: areInactiveMonitorsVisible,
          ...(isCurrentCompanyIdAvailable && {
            currentCompanyId,
          }),
          zoneIds: zoneId,
          ...(alarmFilter !== '' && { alarmFilter }),
        }),
      );
    },
    [
      alarmFilter,
      areInactiveMonitorsVisible,
      currentCompanyId,
      dispatch,
      isCurrentCompanyIdAvailable,
      orderBy,
      orderDirection,
      perPage,
      search,
      zoneId,
    ],
  );

  useEffect(() => {
    dispatch(
      fetchAlarmsCountThunk({
        search,
        ...(isCurrentCompanyIdAvailable && {
          companyId: currentCompanyId,
        }),
        includeInactive: areInactiveMonitorsVisible,
        zoneIds: zoneId,
      }),
    );
  }, [
    areAllCompaniesMonitorsHidden,
    areInactiveMonitorsVisible,
    currentCompanyId,
    dispatch,
    isCurrentCompanyIdAvailable,
    search,
    zoneId,
  ]);

  useEffect(() => {
    if (
      !isInMapMode &&
      currentCompanyResourceStatus === RESOURCE_STATUS.READY
    ) {
      fetchMonitors(page);
      if (isXactAdmin) {
        dispatch(fetchZonesThunk({ companyId: currentCompanyId }));
      }
    }
  }, [
    zoneId,
    alarm,
    dispatch,
    fetchMonitors,
    isXactAdmin,
    isInMapMode,
    currentCompanyId,
    page,
    currentCompanyResourceStatus,
  ]);

  const handleMonitorsSelection = id => {
    if (monitorsAssigned.includes(id)) {
      const index = monitorsAssigned.indexOf(id);
      setMonitorsAssigned([
        ...monitorsAssigned.slice(0, index),
        ...monitorsAssigned.slice(index + 1),
      ]);
    } else {
      setMonitorsAssigned([...monitorsAssigned, id]);
    }
  };

  const getAssignedMonitorsZoneIds = (monitorsList, assignedMonitorsIds) => {
    const assignedMonitors = monitorsList.filter(monitor =>
      assignedMonitorsIds.includes(monitor.id),
    );
    const uniqueZoneIds = [
      ...new Set(assignedMonitors.map(monitor => monitor.zoneId)),
    ];
    return uniqueZoneIds.filter(
      uniqueZoneId => uniqueZoneId !== undefined && uniqueZoneId !== null,
    );
  };

  useEffect(() => {
    if (monitorsAssigned.length > 0) {
      const assignedMonitorsZoneIds = getAssignedMonitorsZoneIds(
        monitors.items,
        monitorsAssigned,
      );

      if (assignedMonitorsZoneIds.length === 1) {
        setSelectedParentZone(assignedMonitorsZoneIds[0]);
      } else {
        setSelectedParentZone(null);
      }
    }
  }, [monitors.items, monitorsAssigned]);

  const handlePagination = itemAmount => {
    setPerPage(itemAmount);
    setPage(1);
  };

  const submitCompanyChange = async id => {
    const params = {
      ids: monitorsAssigned,
      companyId: id,
    };
    const { error } = await dispatch(updateMonitorsCompanyThunk({ params }));

    if (!error) {
      setPage(1);
      setMonitorsAssigned([]);
      fetchMonitors(1);
    }

    const message = error
      ? error.message
      : capitalize(t('monitors_table.company_updated_successfully'));
    const errorStatus = error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS;

    handleToastMessage(toast, message, errorStatus);
  };

  const submitZoneChange = async ({ parentZoneId }) => {
    const params = {
      ids: monitorsAssigned,
      currentCompanyId,
      zoneId: parentZoneId,
    };
    const { error } = await dispatch(updateMonitorsZoneThunk({ params }));

    if (!error) {
      setPage(1);
      setMonitorsAssigned([]);
      fetchMonitors(1);
      await dispatch(fetchZonesThunk({ companyId: currentCompanyId }));
    }

    const message = error
      ? error.message
      : capitalize(t('monitors_table.zone_updated_successfully'));
    const errorStatus = error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS;

    handleToastMessage(toast, message, errorStatus);
  };

  const subscribeToMonitor = async id => {
    const { error } = await dispatch(subscribeToMonitorThunk({ id }));

    if (!error) {
      fetchMonitors(page);
    }

    const message = error
      ? error.message
      : capitalize(t('monitors_table.subscription_message'));
    const errorStatus = error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS;

    handleToastMessage(toast, message, errorStatus);
  };

  const unsubscribeToMonitor = async id => {
    const { error } = await dispatch(unsubscribeToMonitorThunk({ id }));

    if (!error) {
      fetchMonitors(page);
    }
    const message = error
      ? error.message
      : capitalize(t('monitors_table.unsubscription_message'));
    const errorStatus = error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS;

    handleToastMessage(toast, message, errorStatus);
  };

  const handleOrderByChange = value => {
    if (value !== orderBy) {
      setOrderBy(value);
      setOrderDirection(ORDER_DIRECTION.DESCENDENT);
    } else {
      setOrderDirection(prevState =>
        prevState === ORDER_DIRECTION.ASCENDENT
          ? ORDER_DIRECTION.DESCENDENT
          : ORDER_DIRECTION.ASCENDENT,
      );
    }
  };

  if (
    [RESOURCE_STATUS.IDLE, RESOURCE_STATUS.LOADING].includes(
      monitors.resourceStatus,
    ) &&
    firstLoading
  ) {
    return <Spinner display='block' marginX='auto' marginY={32} />;
  }

  return (
    <Flex direction='column' padding='6' height='100%'>
      <Flex p='8' rounded='lg' shadow='md' flexDirection='column' bg='white'>
        <Flex mb='6'>
          <Text variant='heading4xl'>
            {capitalize(t('monitors'))}
            {zoneName && ` ${t('monitors_table.in_zone', { zone: zoneName })}`}
          </Text>
          <Flex width='50%' ml='auto' alignItems='center'>
            <Text variant='headingMd' ml='auto' mr='4'>
              {capitalize(t('monitors_table.switch_to_map'))}
            </Text>
            <Switch
              mt='1.5'
              mr='4'
              color='alpha.500'
              onChange={() => {
                setIsInMapMode(!isInMapMode);
                setFirstLoading(false);
              }}
            />
            <Box w='50%'>
              <SearchBar
                onChange={data => {
                  setMonitorsAssigned([]);
                  setSearch(() => {
                    setPage(1);
                    return data;
                  });
                  setFirstLoading(false);
                }}
                placeholder={`${capitalize(
                  t('monitors_table.search_placeholder'),
                )} ${t('monitors_table.esn')}`}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent='space-between' alignItems='flex-end'>
          <Box width='80%'>
            <SearchFilters
              alarmsCount={{
                value: alarmsCount,
                resourceStatus: alarmsCountResourceStatus,
              }}
              alarmFilter={alarmFilter}
              submitAlarmFilter={data => {
                setAlarmFilter(data);
                setPage(1);
                setFirstLoading(false);
              }}
            />
            <Box mt={6}>
              <Flex alignItems='center' justifyContent='flex-start'>
                <Button
                  hidden={!isXactAdmin && !isCompanyAdminPartner}
                  disabled={monitorsAssigned.length === 0}
                  onClick={onOpenMoveToCompany}
                  mr='4'
                >
                  {capitalize(t('monitors_table.move_to_company'))}
                </Button>
                <Button
                  data-testid='move-to-zone-button'
                  hidden={isCommonUser}
                  disabled={monitorsAssigned.length === 0}
                  onClick={onOpenMoveToZone}
                  mr='4'
                >
                  {capitalize(t('monitors_table.move_to_zone'))}
                </Button>
                <MonitorsDataDownloadAction
                  alarm={alarmFilter}
                  search={search}
                  zoneId={zoneId}
                  currentCompanyId={
                    isCurrentCompanyIdAvailable && currentCompanyId
                  }
                  includeInactive={areInactiveMonitorsVisible}
                />
              </Flex>
            </Box>
          </Box>
          <Stack direction='column' width='45%'>
            {isCurrentCompanyDefault && isXactAdmin && (
              <Flex alignItems='center'>
                <Text variant='headingMd' ml='auto' mr='4'>
                  {capitalize(
                    t('monitors_table.hide_monitors_from_other_companies'),
                  )}
                </Text>
                <Switch
                  mt='1.5'
                  mr='4'
                  color='alpha.500'
                  onChange={() => {
                    setAreAllCompaniesMonitorsHidden(
                      !areAllCompaniesMonitorsHidden,
                    );
                    setFirstLoading(false);
                  }}
                />
              </Flex>
            )}
            <Flex alignItems='center'>
              <Text variant='headingMd' ml='auto' mr='4'>
                {capitalize(t('show_inactive_monitors'))}
              </Text>
              <Switch
                mt='1.5'
                mr='4'
                color='alpha.500'
                onChange={() => {
                  setAreInactiveMonitorsVisible(!areInactiveMonitorsVisible);
                  setFirstLoading(false);
                }}
              />
            </Flex>
          </Stack>
        </Flex>
      </Flex>
      {isInMapMode && mapMonitors.resourceStatus === READY && (
        <Flex
          direction='column'
          position='relative'
          flex='1'
          h='100%'
          px='2'
          justifyContent='center'
        >
          {monitorsWithCoordinates.length !== 0 ? (
            <Box w='100%' h='100%' ref={mapContainer} />
          ) : (
            <>
              <Text textAlign='center' mt='1' variant='headingMd'>
                {capitalize(t('map.no_monitors_message'))}
              </Text>
            </>
          )}
        </Flex>
      )}
      {!isInMapMode && monitors.resourceStatus === READY && (
        <>
          <Flex alignItems='center'>
            <Box w='100%'>
              <Filters
                {...(monitors?.items.length !== 0 && {
                  pagination: {
                    page,
                    perPage,
                    perPageValues: PER_PAGE_VALUES,
                    handlePagination,
                    totalPages: monitors.totalPages,
                    changePage: data => {
                      setPage(data);
                      setFirstLoading(false);
                    },
                    totalCount: monitors.totalCount,
                  },
                })}
              />
            </Box>
          </Flex>

          {monitors?.items.length === 0 && monitors.resourceStatus === READY ? (
            <MonitorsEmptyState />
          ) : (
            <Box rounded='lg' bg='white' marginBottom={4}>
              <Box p='6'>
                <Table>
                  <Thead>
                    <Tr>
                      {COLUMN_HEADERS.map(
                        ({ transform, name, icon, orderField }) => (
                          <ColumnHeader
                            orderDirection={
                              orderField === orderBy && orderDirection
                            }
                            onClick={() => handleOrderByChange(orderField)}
                            key={name}
                            value={t(`table_columns.${name}`)}
                            icon={icon}
                            iconTransform={transform}
                            sortable={
                              name !== 'last_delivery' &&
                              name !== 'alarms' &&
                              name !== 'company'
                            }
                          />
                        ),
                      )}
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody paddingY={5}>
                    {monitors.items.map(monitor => (
                      <Row
                        key={monitor.id}
                        monitor={monitor}
                        t={t}
                        selectMonitor={handleMonitorsSelection}
                        isCommonUser={isCommonUser}
                        subscribeToMonitor={subscribeToMonitor}
                        unsubscribeToMonitor={unsubscribeToMonitor}
                        isChecked={monitorsAssigned.includes(monitor.id)}
                        isXactAdmin={isXactAdmin}
                      />
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          )}
        </>
      )}
      {[IDLE, LOADING].includes(monitors.resourceStatus) && (
        <Box>
          <Spinner display='block' marginX='auto' />
        </Box>
      )}
      {[IDLE, LOADING].includes(mapMonitors.resourceStatus) && isInMapMode && (
        <Box>
          <Spinner display='block' marginX='auto' />
        </Box>
      )}
      <AssignMonitorsToCompanyModal
        companies={companies}
        isOpen={isMoveToCompanyOpen}
        close={onCloseMoveToCompany}
        submitCompanyChange={submitCompanyChange}
      />
      <ZoneModal
        isOpen={isMoveToZoneOpen}
        close={onCloseMoveToZone}
        isMoveToZone
        zones={zones}
        submitData={submitZoneChange}
        checkedZoneIds={[selectedParentZone]}
      />
    </Flex>
  );
};

MonitorsTable.propTypes = {
  t: tType,
};

export default withTranslation()(MonitorsTable);
