import { Button } from '@chakra-ui/react';
import i18n from 'lib/i18n';
import { number, string, bool, tType } from 'prop-types';
import React, { useEffect } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadMonitorThunk,
  getDownload,
  setDownloadStatusIdle,
} from 'stores/monitors';
import { RESOURCE_STATUS } from 'utils/constants';
import { downloadFile } from 'utils/downloadUtils';
import capitalize from 'lodash.capitalize';

const { withTranslation } = i18n;

const MonitorsDataDownloadAction = ({
  alarm,
  search,
  zoneId,
  currentCompanyId,
  includeInactive,
  t,
}) => {
  const dispatch = useDispatch();
  const {
    value: download,
    resourceStatus: downloadResourceStatus,
  } = useSelector(getDownload);

  const getFilename = contentDisposition => {
    const stringSplitted = contentDisposition.split(';');
    const fileName = stringSplitted[2];

    return fileName.split("'")[2];
  };

  useEffect(() => {
    if (downloadResourceStatus === RESOURCE_STATUS.READY) {
      const file = new Blob([download.data], {
        type: 'text/csv',
      });
      const filename = getFilename(download.headers['content-disposition']);
      const url = URL.createObjectURL(file);
      try {
        downloadFile(filename, url);
      } catch (error) {
        throw new Error(error.message);
      }
      dispatch(setDownloadStatusIdle());
    }
  }, [dispatch, download, download.data, downloadResourceStatus]);

  const downloadMonitorsData = () => {
    dispatch(
      downloadMonitorThunk({
        ...(alarm !== '' && { alarm }),
        search,
        zoneId,
        ...(currentCompanyId && { currentCompanyId }),
        includeInactive,
      }),
    );
  };

  return (
    <Button
      onClick={downloadMonitorsData}
      mr='4'
      rightIcon={<IoDownloadOutline fontSize='20px' />}
      disabled={downloadResourceStatus === RESOURCE_STATUS.READY}
    >
      {capitalize(t('download_csv'))}
    </Button>
  );
};

MonitorsDataDownloadAction.propTypes = {
  alarm: string,
  search: string,
  zoneId: number,
  currentCompanyId: string,
  includeInactive: bool,
  t: tType,
};

export default withTranslation()(MonitorsDataDownloadAction);
