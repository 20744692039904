import React from 'react';
import { Flex, Td, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { tType } from 'types';

const { withTranslation } = i18n;

const UllageCell = ({ fontSize, ullage, unit, t }) => {
  const renderUllage = () => (
    <Flex alignItems='center' fontSize={fontSize} variant='textXs'>
      <Text>
        {Number(ullage).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </Text>
      <Text ml={1}>{t(unit)}</Text>
    </Flex>
  );

  return <Td>{ullage && renderUllage()}</Td>;
};

UllageCell.propTypes = {
  fontSize: PropTypes.string,
  ullage: PropTypes.string,
  unit: PropTypes.string,
  t: tType,
};

export default withTranslation()(UllageCell);
