import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import Select from 'components/Select';
import i18n from 'lib/i18n';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { tType } from 'types';
import capitalize from 'lodash.capitalize';

const { withTranslation } = i18n;

const AssignMonitorsToCompanyModal = ({
  t,
  isOpen,
  close,
  companies,
  submitCompanyChange,
}) => {
  const [companySelected, setCompanySelected] = useState({
    name: '',
    value: 0,
  });

  const clearModal = () => {
    setCompanySelected({ name: '', value: 0 });
    close();
  };

  const moveToCompany = () => {
    setCompanySelected({ name: '', value: 0 });
    submitCompanyChange(companySelected.value);
    close();
  };
  return (
    <Modal isOpen={isOpen} onClose={clearModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {capitalize(t('monitors_table.move_monitors'))}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb='6'>
            {capitalize(t('monitors_table.move_monitors_message'))}
          </Text>
          <Select
            placeholder={capitalize(t('select_company'))}
            listHeight='350px'
            value={companySelected.name}
          >
            {companies.map(({ id, name }) => (
              <Text
                onClick={() => setCompanySelected({ name, value: id })}
                py='4'
                px='2'
                key={id}
                _hover={{ bg: 'gray.100' }}
              >
                {name}
              </Text>
            ))}
          </Select>
          <Flex marginTop='12' pb='4'>
            <Spacer />
            <Button mr={3} onClick={clearModal} variant='link'>
              {capitalize(t('cancel'))}
            </Button>
            <Button onClick={moveToCompany}>{capitalize(t('move'))}</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

AssignMonitorsToCompanyModal.propTypes = {
  t: tType,
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.object),
  submitCompanyChange: PropTypes.func,
};

export default withTranslation()(AssignMonitorsToCompanyModal);
