import { Box, Flex, Icon, Text, useOutsideClick } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IoChevronDownSharp } from 'react-icons/io5';

const Select = ({ value, children, listHeight, placeholder }) => {
  const ref = useRef();
  const [isListVisible, setIsListVisible] = useState(false);
  const valuePresented = value === '' ? placeholder : value;
  const setColor = value === '' && 'gray.400';

  useOutsideClick({
    enabled: isListVisible,
    handler: () => setIsListVisible(false),
    ref,
  });

  return (
    <>
      <Flex
        cursor='pointer'
        borderColor='gray.500'
        border='1px'
        rounded='md'
        p='2'
        alignItems='center'
        onClick={() => setIsListVisible(!isListVisible)}
        justifyContent='space-between'
      >
        <Text data-testid='selected-value' color={setColor}>
          {valuePresented}
        </Text>
        <Icon as={IoChevronDownSharp} boxSize='4' color='black' />
      </Flex>
      <Box
        cursor='pointer'
        shadow='lg'
        rounded='md'
        width='100%'
        mt='3'
        p='1'
        bg='white'
        maxHeight={listHeight}
        overflow='auto'
        zIndex='1'
        position='absolute'
        hidden={!isListVisible}
      >
        {children}
      </Box>
    </>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node.isRequired,
  listHeight: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Select;
