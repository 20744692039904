import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Td, Text } from '@chakra-ui/react';
import TankIcon from 'components/icons/TankIcon';
import i18n from 'lib/i18n';
import { tType } from 'types';

const { withTranslation } = i18n;

const InventoryCell = ({ fontSize, inventory, inventoryRatio, unit, t }) => {
  const inventoryRatioNumber = Number(inventoryRatio);

  const renderInventory = () => (
    <Flex>
      <Box w='37px'>
        <TankIcon
          inventoryRatio={inventoryRatioNumber}
          color='#004D96'
          boxSize='3em'
        />
      </Box>
      <Flex ml='4' justifyContent='center' direction='column'>
        <Text fontSize={fontSize} variant='headingXs'>
          {`${Number(inventoryRatio).toFixed(0)}%`}
        </Text>
        <Flex alignItems='center' fontSize={fontSize} variant='textXs'>
          <Text>
            {Number(inventory).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Text>
          <Text ml={1}>{t(unit)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );

  return <Td>{inventory && renderInventory()}</Td>;
};

InventoryCell.propTypes = {
  fontSize: PropTypes.string,
  inventory: PropTypes.string,
  inventoryRatio: PropTypes.string,
  unit: PropTypes.string,
  t: tType,
};

export default withTranslation()(InventoryCell);
