import { createIcon } from '@chakra-ui/react';
import React from 'react';

const ArrowIcon = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 18 21',
  fill: 'none',
  path: (
    <>
      <g clipPath='url(#clip0_1148_4544)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.35254 7.55652L12.9323 3.97679L16.512 7.55652L15.7165 8.35202L12.9323 5.56778L10.148 8.35201L9.35254 7.55652Z'
          fill='#414D58'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.4942 4.71777L13.495 20.7089L12.37 20.709L12.3692 4.71782L13.4942 4.71777Z'
          fill='#414D58'
        />
      </g>
      <defs>
        <clipPath id='clip0_1148_4544'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='translate(0.205078 12.7279) rotate(-45)'
          />
        </clipPath>
      </defs>
    </>
  ),
});

export default ArrowIcon;
