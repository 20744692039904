import React from 'react';
import { func, bool } from 'prop-types';
import moment from 'moment';
import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Icon,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import { tankType, tType } from 'types';
import UllageCell from 'components/UllageCell';
import InventoryCell from 'components/InventoryCell';
import { DATE_TYPE } from 'utils/constants';
import { formatDate } from 'utils/dateUtils';
import { IoNotifications, IoNotificationsOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'stores/currentUser';
import { isGallonsOrLiters } from 'utils/measureUnits';
import i18n from 'lib/i18n';
import { monitorURL } from 'utils/routingUtils';
import Link from 'next/link';
import capitalize from 'lodash.capitalize';
import startcase from 'lodash.startcase';
import { getCompanyList } from 'stores/company';
import { getCurrentCompanyId } from 'lib/auth';

const { withTranslation } = i18n;

const Row = ({
  monitor,
  selectMonitor,
  isCommonUser,
  subscribeToMonitor,
  unsubscribeToMonitor,
  isChecked,
  isXactAdmin,
  t,
}) => {
  const { description, volume, zone } = monitor;
  const currentUser = useSelector(getCurrentUser);
  const currentCompanyId = getCurrentCompanyId();
  const { measureUnit } = currentUser;
  const { inventory, inventoryRatio, ullage } = monitor?.currentLevel || {};
  const unit = isGallonsOrLiters(measureUnit);
  const companies = useSelector(getCompanyList);
  const monitorCompany = companies?.find(
    company => company?.id === zone?.companyId,
  );

  const alarms = [
    {
      type: 'high',
      bg: 'beta.400',
    },
    {
      type: 'low',
      bg: 'zeta.400',
    },
    {
      type: 'low_battery',
      bg: 'epsilon.400',
    },
    {
      type: 'late_reports',
      bg: 'gray.400',
    },
  ];

  return (
    <Tr key={monitor.id}>
      <Td px={3} verticalAlign='baseline' py='6'>
        <Flex alignItems='flex-start'>
          <Flex mr='3' mt='1'>
            <Checkbox
              onChange={() => selectMonitor(monitor.id)}
              hidden={
                isCommonUser ||
                (isXactAdmin && Number(currentCompanyId) !== monitorCompany?.id)
              }
              isChecked={isChecked}
            />
          </Flex>
          <Box width='100%' height='100%' cursor='pointer'>
            <Flex alignItems='flex-start' marginBottom={2} maxWidth={240}>
              <Text variant='headingSm' marginRight={2}>
                <Link href={monitorURL(monitor.id)}>
                  {description || `${monitor.tankMonitor?.esn}`}
                </Link>
              </Text>
            </Flex>
            <Box>
              <Text marginRight={1} variant='textXs'>
                <Link href={monitorURL(monitor.id)}>
                  {`ESN: ${monitor.tankMonitor?.esn}`}
                </Link>
              </Text>
              {monitor.tankMonitor?.externalId && (
                <Text variant='textXs'>
                  <Link href={monitorURL(monitor.id)}>
                    {`${capitalize(t('external'))}: ${
                      monitor.tankMonitor?.externalId
                    }`}
                  </Link>
                </Text>
              )}
              <Text variant='textXs'>
                <Link href={monitorURL(monitor.id)}>{`${capitalize(
                  t('zone'),
                )}: ${zone?.name}`}</Link>
              </Text>
              <Text variant='textXs'>
                <Link href={monitorURL(monitor.id)}>{`${capitalize(
                  t('status'),
                )}: ${monitor.tankMonitor?.status}`}</Link>
              </Text>
            </Box>
          </Box>
        </Flex>
      </Td>
      <Td>{monitorCompany?.name}</Td>
      <Td>
        <Flex flexDirection='column' justifyContent='space-around'>
          {monitor?.alarms?.map(alarm => {
            const alarmTypeSelected = alarms.find(
              a => a.type === alarm.alarmType,
            );
            return (
              <Tooltip
                label={startcase(alarm?.name)}
                placement='right-end'
                color='white'
                bg='gray.700'
              >
                <Badge
                  cursor='pointer'
                  backgroundColor={alarmTypeSelected && alarmTypeSelected.bg}
                  w='17px'
                  h='10px'
                  py='2px'
                  px='5px'
                  rounded='sm'
                  my='1'
                />
              </Tooltip>
            );
          })}
        </Flex>
      </Td>
      <InventoryCell
        fontSize='sm'
        inventory={inventory}
        inventoryRatio={inventoryRatio}
        unit={unit}
      />
      <UllageCell fontSize='sm' ullage={ullage} unit={unit} />
      <Td>
        {volume ? (
          <Flex alignItems='center' fontSize='sm'>
            <Text>
              {Number(volume).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Text>
            <Text ml={1}>{t(unit)}</Text>
          </Flex>
        ) : (
          <Text>N/A</Text>
        )}
      </Td>

      <Td px='3' textAlign='center'>
        <Text variant='textSm'>{`${formatDate(
          monitor.lastReadingAt,
          DATE_TYPE.LONG,
          'en',
        )}`}</Text>
        <Text variant='textSm' color='gray.400'>
          {moment(monitor.lastReadingAt).fromNow()}
        </Text>
      </Td>
      <Td>
        {monitor.userSubscribed ? (
          <Icon
            cursor='pointer'
            as={IoNotifications}
            color='epsilon.500'
            boxSize='5'
            onClick={() => unsubscribeToMonitor(monitor.id)}
          />
        ) : (
          <Icon
            cursor='pointer'
            as={IoNotificationsOutline}
            boxSize='5'
            onClick={() => subscribeToMonitor(monitor.id)}
          />
        )}
      </Td>
    </Tr>
  );
};

Row.propTypes = {
  monitor: tankType,
  selectMonitor: func,
  isCommonUser: bool,
  subscribeToMonitor: func,
  unsubscribeToMonitor: func,
  isChecked: bool,
  isXactAdmin: bool,
  t: tType,
};

export default withTranslation()(Row);
